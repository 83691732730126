import { Typography, TypographyProps } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { cn } from "../../../../helpers/cn";
import { useMaterialCardContext } from "./MaterialCardProvider";

type MaterialCardTitleProps = TypographyProps;

export const MaterialCardTitle = ({
  className,
  ...props
}: MaterialCardTitleProps) => {
  const componentColors = useSelector(
    (state: RootState) => state.theme.componentColors,
  );
  const { state } = useMaterialCardContext();

  return (
    <Typography
      variant="body1"
      className={cn("text-lg font-medium", className)}
      sx={{
        color: componentColors.surface.onSurface,
      }}
      {...props}
    >
      {state.data.title}
    </Typography>
  );
};
