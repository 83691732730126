import { Typography, TypographyProps } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

type SliderTitleProps = TypographyProps;

export const SliderTitle = ({
  sx,
  variant = "h2",
  className,
  children,
}: SliderTitleProps) => {
  const colors = useSelector((state: RootState) => state.theme.componentColors);

  return (
    <Typography
      sx={{
        color: colors.surface.onSurfaceDetachable,
        fontWeight: 500,
        fontSize: "1.875rem",
        lineHeight: "2.25rem",
        ...sx,
      }}
      variant={variant}
      className={className}
    >
      {children}
    </Typography>
  );
};
