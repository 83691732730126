import { Typography } from "@mui/material";
import React from "react";

type BadgeExplanationTitleProps = React.ComponentProps<"p">;

export const BadgeExplanationTitle = ({
  children,
}: BadgeExplanationTitleProps) => {
  return <Typography>{children}</Typography>;
};
