import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { useMaterialCardContext } from "./MaterialCardProvider";

export const MaterialCardCollabTitle = () => {
  const componentColors = useSelector(
    (state: RootState) => state.theme.componentColors,
  );
  const { state } = useMaterialCardContext();
  const data = state.data;

  return (
    <Typography
      className="text-[14px] overflow-ellipsis "
      sx={{
        color: componentColors.surface.onSurface,
      }}
    >
      {data.user.name}
      {data.collaborators.length > 0
        ? `, with ${data.collaborators[0].title}`
        : null}
      {data.collaborators.length === 2
        ? ` & ${data.collaborators[1].title}`
        : null}
    </Typography>
  );
};
