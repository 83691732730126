import React from "react";
import { cn } from "../../../../helpers/cn";

type BadgeExplanationContainerProps = React.ComponentProps<"div">;

export const BadgeExplanationContainer = ({
  children,
  className,
}: BadgeExplanationContainerProps) => {
  return (
    <div className={cn("flex flex-col items-start gap-2", className)}>
      {children}
    </div>
  );
};
