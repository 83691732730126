import React from "react";
import "swiper/css/bundle";
import { cn } from "../../../../helpers/cn";
import { Swiper } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";

const defaultBreakpoints = {
  250: {
    width: 200,
    slidesPerView: 1,
  },
  300: {
    slidesPerView: 1,
    width: 320,
  },
  800: {
    slidesPerView: 2,
    width: 750,
  },
  1000: {
    slidesPerView: 2,
    width: 950,
  },
  1400: {
    slidesPerView: 3,
    width: 1200,
  },
};

type SliderProps = {
  customSwiperId?: string;
  spaceBetween?: number;
  className?: string;
  breakpoints?: {
    [key: number]: { slidesPerView: number; width: number };
  };
  children: React.ReactNode;
  onInit?: () => void;
};

export const Slider = ({
  spaceBetween = 10,
  customSwiperId = "slider",
  breakpoints = defaultBreakpoints,
  children,
  className,
  ...props
}: SliderProps) => {
  const [shouldShowSwiper, setShouldShowSwiper] = React.useState<boolean>(null);

  return (
    <Swiper
      id={customSwiperId}
      className={cn(
        `w-full overflow-hidden pb-10 relative`,
        `${shouldShowSwiper ? "" : "hidden"}`,
        className,
      )}
      spaceBetween={spaceBetween}
      breakpoints={breakpoints}
      key={customSwiperId}
      navigation={{
        prevEl: `#custom-left-arrow-${customSwiperId}`,
        nextEl: `#custom-right-arrow-${customSwiperId}`,
      }}
      pagination={{
        dynamicBullets: true,
        dynamicMainBullets: 2,
      }}
      modules={[Navigation, Pagination]}
      onSwiper={() => {
        setShouldShowSwiper(true);
      }}
      {...props}
    >
      {children}
    </Swiper>
  );
};
