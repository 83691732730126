import React from "react";
import { cn } from "../../../../helpers/cn";

type MaterialCardBottomWrapperProps = React.ComponentProps<"div">;

export const MaterialCardBottomWrapper = ({
  children,
  className,
  ...props
}: MaterialCardBottomWrapperProps) => {
  return (
    <div
      className={cn("flex justify-start space-x-[16px]", className)}
      {...props}
    >
      {children}
    </div>
  );
};
