import { AppIconButton } from "../../ui/buttons";
import { Close } from "@mui/icons-material";
import { useModalContext } from "../../modals/ModalProvider";

export const BadgeVerificationModalCloseBtn = () => {
  const { closeModal } = useModalContext();

  const handleCloseModal = () => {
    closeModal();
  };

  return (
    <AppIconButton
      className={"absolute right-6 top-6 hidden lg:flex "}
      onClick={handleCloseModal}
    >
      <Close />
    </AppIconButton>
  );
};
