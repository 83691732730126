import { cn } from "../../../../helpers/cn";
import React from "react";

type SliderHeaderWrapperProps = React.ComponentProps<"div">;

export const SliderHeaderWrapper = ({
  children,
  className,
  ...props
}: SliderHeaderWrapperProps) => {
  return (
    <div
      className={cn("flex items-center justify-between w-full", className)}
      {...props}
    >
      {children}
    </div>
  );
};
