import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { Rating, RatingProps } from "@mui/material";

type AppRatingProps = {
  starSize?: string;
  spaceBetweenStars?: string;
} & RatingProps;

export const AppRating = ({
  value,
  spaceBetweenStars = "10px",
  starSize = "16px",
  max = 5,
  ...props
}: AppRatingProps) => {
  const theme = useSelector((state: RootState) => state.theme);

  return (
    <Rating
      max={max}
      readOnly
      value={value}
      precision={0.5}
      sx={{
        color: theme.componentColors.primary.primary,
        fontSize: starSize,
        "&.MuiRating-root .MuiRating-decimal": {
          marginRight: spaceBetweenStars,
        },
      }}
      {...props}
    />
  );
};
