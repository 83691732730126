import React from "react";
import { BadgeVerificationModal } from "./BadgeVerificationModal";
import { useModalContext } from "../modals/ModalProvider";
import { BadgeVerificationContainerWrapper } from "./BadgeVerificationContainerWrapper";

type BadgeVerificationContainerProps = React.ComponentProps<"div"> & {
  shouldClick?: boolean;
};

export const BadgeVerificationContainer = ({
  children,
  shouldClick,
  ...props
}: BadgeVerificationContainerProps) => {
  const { showModal } = useModalContext();

  const clickBadgeHandler = (e: React.MouseEvent<HTMLDivElement>) => {
    if (shouldClick) {
      e.stopPropagation();
      showModal(<BadgeVerificationModal />);
    }
  };

  return (
    <BadgeVerificationContainerWrapper onClick={clickBadgeHandler} {...props}>
      {children}
    </BadgeVerificationContainerWrapper>
  );
};
