import React, { useEffect, useRef, useState } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { IVerticalSlider } from "../../../interfaces/globalComponents/sliders/IVerticalSlider";
import "swiper/css";

function VerticalSlider({ data, Component, title }: IVerticalSlider) {
  const swiperRef = useRef<SwiperCore | null>(null);
  const [disabledLeftBtn, setDisabledLeftButton] = useState(false);
  const [disabledRightBtn, setDisabledRightButton] = useState(false);
  const [groups, setGroups] = useState<Array<Array<unknown>>>([]);
  useEffect(() => {
    const quantityOfGroupsCalc =
      Math.trunc(data.length / 3) + (data.length % 3 !== 0 ? 1 : 0);
    const tempGroups: Array<unknown[]> = [];
    let initialValueLoop = 0;
    let endingValueLoop = 3;
    for (let j = 0; j < quantityOfGroupsCalc; j++) {
      tempGroups[j] = [];
      for (let i = initialValueLoop; i < endingValueLoop; i++) {
        if (data[i]) {
          tempGroups[j].push(data[i]);
        }
      }
      initialValueLoop += 3;
      endingValueLoop += 3;
    }
    setGroups(tempGroups);
  }, [data]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (swiperRef.current?.isBeginning) {
      setDisabledLeftButton(true);
    } else {
      setDisabledLeftButton(false);
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (swiperRef.current?.isEnd) {
      setDisabledRightButton(true);
    } else {
      setDisabledRightButton(false);
    }
  }, []);

  return (
    <div className=" flex flex-col w-full mx-auto space-y-6 overflow-hidden">
      <div className="flex justify-between">
        <h2 className={`text-xl font-semibold`}>{title}</h2>
        <div className="flex space-x-3 ">
          <div>
            <button
              disabled={disabledLeftBtn}
              className={`bg-gray-300 rounded-full w-8 h-8 justify-center items-center cursor-pointer flex ${
                disabledLeftBtn ? "opacity-50" : ""
              }`}
              onClick={() => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
                swiperRef.current?.slidePrev();
                setDisabledRightButton(false);
              }}
            >
              <IoIosArrowBack className="text-sm lg:w-4 lg:h-8" />
            </button>
          </div>
          <div>
            <button
              disabled={disabledRightBtn}
              className={`${
                disabledRightBtn ? "opacity-50" : ""
              } bg-gray-300 rounded-full w-8 h-8 justify-center items-center cursor-pointer flex`}
              onClick={() => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
                swiperRef.current?.slideNext();
                setDisabledLeftButton(false);
              }}
            >
              <IoIosArrowForward className="text-sm lg:w-4 lg:h-8 " />
            </button>
          </div>
        </div>
      </div>

      {groups.length > 0 && (
        <Swiper
          spaceBetween={30}
          slidesPerView={1}
          width={295}
          onBeforeInit={(swiper) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            swiperRef.current = swiper;
          }}
          breakpoints={{
            1200: {
              width: 360,
            },
          }}
          onSlideChange={(swiper) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (!swiper.isBeginning && !swiper.isEnd) {
              setDisabledRightButton(false);
              setDisabledLeftButton(false);
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            } else if (swiper.isBeginning) {
              setDisabledRightButton(false);
              setDisabledLeftButton(true);
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            } else if (swiper.isEnd) {
              setDisabledRightButton(true);
              setDisabledLeftButton(false);
            }
          }}
        >
          {groups?.map((group, i) => {
            return (
              <SwiperSlide key={`group-${i}`}>
                {group?.map((item, i) => {
                  return <Component data={item} key={i} />;
                })}
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}
    </div>
  );
}

export default VerticalSlider;
