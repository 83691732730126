import React from "react";
import { cn } from "../../../../helpers/cn";

type SliderHeaderTextWrapperProps = React.ComponentProps<"div">;

export const SliderHeaderTextWrapper = ({
  children,
  className,
  ...props
}: SliderHeaderTextWrapperProps) => {
  return (
    <div
      className={cn("flex items-center justify-center flex-col", className)}
      {...props}
    >
      {children}
    </div>
  );
};
