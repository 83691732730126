import { cn } from "../../../helpers/cn";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

type BadgeVerificationContainerWrapperProps = React.ComponentProps<"div">;

export const BadgeVerificationContainerWrapper = ({
  className,
  children,
  ...props
}: BadgeVerificationContainerWrapperProps) => {
  const theme = useSelector((state: RootState) => state.theme);

  return (
    <div
      style={{
        backgroundColor: theme.componentColors.surface.surfContainerHigh,
      }}
      className={cn(
        `w-8 h-8 rounded-full flex flex-col items-center justify-center cursor-pointer`,
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
};
