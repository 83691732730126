import { CardMedia, CardMediaProps } from "@mui/material";
import useImageSize from "../../../../hooks/global/useImageSize";
import { useMaterialCardContext } from "./MaterialCardProvider";
import { cn } from "../../../../helpers/cn";

type MaterialCardMediaProps = CardMediaProps;

export const MaterialCardMedia = ({
  children,
  className,
  ...props
}: MaterialCardMediaProps) => {
  const { state } = useMaterialCardContext();

  const resizedImage = useImageSize({
    imageUrl: state.data?.coverImage,
    size: 500,
    isCover: true,
    originalFormat: true,
  })[0];

  return (
    <CardMedia
      image={resizedImage}
      className={cn("aspect-video rounded-[8px]", className)}
      {...props}
    />
  );
};
