import { Typography, TypographyProps } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { useTrainingPlanLevelsTextCard } from "../../../../hooks/trainingPlan/useTrainingPlanLevelsTextCard";
import { cn } from "../../../../helpers/cn";
import { useMaterialCardContext } from "./MaterialCardProvider";

type MaterialCardSubtitleProps = TypographyProps;

export const MaterialCardSubtitle = ({
  className,
  ...props
}: MaterialCardSubtitleProps) => {
  const componentColors = useSelector(
    (state: RootState) => state.theme.componentColors,
  );
  const { state } = useMaterialCardContext();
  const { levelsTextCard } = useTrainingPlanLevelsTextCard(
    state.data?.levels ?? [],
  );

  return (
    <Typography
      variant="body2"
      sx={{
        color: componentColors.surface.onSurfaceVariant,
      }}
      className={cn("capitalize text-[14px]", className)}
      {...props}
    >
      {levelsTextCard}
    </Typography>
  );
};
