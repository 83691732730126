import { cn } from "../../../../helpers/cn";
import React from "react";

type SliderHeaderWrapperContainerProps = React.ComponentProps<"div">;

export const SliderHeaderWrapperContainer = ({
  className,
  children,
  ...props
}: SliderHeaderWrapperContainerProps) => {
  return (
    <div
      className={cn("flex flex-col items-start justify-start", className)}
      {...props}
    >
      {children}
    </div>
  );
};
