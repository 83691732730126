import React from "react";
import { BadgeOptions } from "../../../enums/BadgeOptions";
import VerifiedIcon from "@mui/icons-material/Verified";
import { verificationBadgeColor } from "../../../lib/specialist/verificationBadgeColor";
import { BadgeVerificationContainer } from "./BadgeVerificationContainer";

type BadgeVerificationProps = React.ComponentProps<"div"> & {
  type: BadgeOptions.pro | BadgeOptions.unverified | BadgeOptions.verified;
  isFromSpecialist?: boolean;
  isFromTeam?: boolean;
  shouldClick?: boolean;
};

export const BadgeVerification = ({
  type,
  isFromSpecialist = false,
  isFromTeam = false,
  shouldClick = false,
  className,
}: BadgeVerificationProps) => {
  const badgeColor = verificationBadgeColor({
    isFromSpecialist,
    isFromTeam,
    type,
  });

  return (
    <BadgeVerificationContainer shouldClick={shouldClick} className={className}>
      <VerifiedIcon
        sx={{
          color: badgeColor,
          width: "1.25rem",
          height: "1.25rem",
        }}
      />
    </BadgeVerificationContainer>
  );
};
