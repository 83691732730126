import { MaterialCardCoachAvatar } from "./MaterialCardCoachAvatar";
import { MaterialCardCollaborator } from "./MaterialCardCoachCollaborator";
import React from "react";
import { useMaterialCardContext } from "./MaterialCardProvider";

export const MaterialCardCoachAndCollab = () => {
  const { state } = useMaterialCardContext();
  const collaborators = state.data.collaborators;
  const coach = state.data.user;

  if (collaborators.length === 0) {
    return <MaterialCardCoachAvatar coach={coach} />;
  }

  if (collaborators.length > 0) {
    return (
      <div className="relative h-full">
        <MaterialCardCoachAvatar coach={coach} />
        {collaborators.map((collaborator, i) => (
          <MaterialCardCollaborator
            key={i}
            position={i + 1}
            collaborator={collaborator}
          />
        ))}
      </div>
    );
  }
};
