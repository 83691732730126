import React from "react";
import { cn } from "../../../../helpers/cn";

type SliderButtonsWrapperProps = React.ComponentProps<"div">;

export const SliderButtonsWrapper = ({
  children,
  className,
}: SliderButtonsWrapperProps) => {
  return (
    <div className={cn("flex items-center justify-center", className)}>
      {children}
    </div>
  );
};
