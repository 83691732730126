import React, { useRef } from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import { IDraggableSlider } from "../../../interfaces/globalComponents/sliders/IDraggableSlider";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { SwiperCustomArrow } from "./material/slidersResources/SwiperCustomArrow";
import SwiperCore from "swiper";

function DraggableSlider({
  data,
  Component,
  title,
  customSwiperId = "swiper",
  isLoading = false,
  showTitle = true,
  spaceBetween = 10,
  breakpointsObject = {
    250: {
      width: 200,
      slidesPerView: 1,
    },
    300: {
      slidesPerView: 1,
      width: 320,
    },
    800: {
      slidesPerView: 2,
      width: 750,
    },
    1000: {
      slidesPerView: 2,
      width: 950,
    },
    1400: {
      slidesPerView: 3,
      width: 1200,
    },
  },
  showTopButtons = false,
}: IDraggableSlider) {
  const swiperRef = useRef<SwiperCore | null>(null);
  const theme = useSelector((state: RootState) => state.theme);

  return (
    <div className={`flex flex-col w-full ${!showTitle ? "" : "space-y-5"}`}>
      <div className="flex justify-between items-center">
        {title && (
          <Typography
            sx={{
              color: theme.componentColors.surface.surfaceFull,
            }}
            variant="h2"
            className={`text-[1.375rem] lg:text-2xl font-medium ${
              !showTitle ? "hidden" : ""
            } `}
          >
            {title}
          </Typography>
        )}

        {showTopButtons && !isLoading && (
          <div className="flex space-x-3">
            <SwiperCustomArrow
              Icon={ChevronLeftIcon}
              isHalfTop={false}
              id={
                showTitle
                  ? `custom-left-arrow-${customSwiperId}`
                  : "custom-left-arrow"
              }
            />
            <SwiperCustomArrow
              isHalfTop={false}
              Icon={ChevronRightIcon}
              id={
                showTitle
                  ? `custom-right-arrow-${customSwiperId}`
                  : "custom-right-arrow"
              }
            />
          </div>
        )}
      </div>
      <div className="overflow-hidden relative">
        <Swiper
          modules={[Navigation, Pagination]}
          key={data.join("")}
          className={`w-full overflow-visible pb-10`}
          spaceBetween={spaceBetween}
          roundLengths={true}
          onBeforeInit={(swiper: SwiperCore) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            swiperRef.current = swiper;
          }}
          navigation={{
            prevEl: `#custom-left-arrow-${customSwiperId}`,
            nextEl: `#custom-right-arrow-${customSwiperId}`,
          }}
          pagination={{
            dynamicBullets: true,
            dynamicMainBullets: 2,
          }}
          breakpoints={{ ...breakpointsObject }}
        >
          {data?.length > 0 &&
            data?.map((item: unknown, idx: number) => {
              return (
                <SwiperSlide key={idx}>
                  <Component key={idx} data={item} />
                </SwiperSlide>
              );
            })}

          {!showTopButtons && !isLoading && (
            <>
              <SwiperCustomArrow
                Icon={ChevronLeftIcon}
                id={`custom-left-arrow-${customSwiperId}`}
              />
              <SwiperCustomArrow
                Icon={ChevronRightIcon}
                id={`custom-right-arrow-${customSwiperId}`}
              />{" "}
            </>
          )}
        </Swiper>
      </div>
    </div>
  );
}

export default DraggableSlider;
