import { CardContent, CardContentProps } from "@mui/material";

type MaterialCardContentWrapperProps = CardContentProps;

export const MaterialCardContentWrapper = ({
  children,
  className,
  ...props
}: MaterialCardContentWrapperProps) => {
  return (
    <CardContent
      className="flex flex-col items-start justify-between space-y-[16px] "
      {...props}
    >
      {children}
    </CardContent>
  );
};
