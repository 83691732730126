import { MaterialCardLink } from "./MaterialCardLink";
import { MaterialCardWrapper } from "./MaterialCardWrapper";
import { MaterialCardContentWrapper } from "./MaterialCardContentWrapper";
import { MaterialCardMedia } from "./MaterialCardMedia";
import { MaterialCardContentHeader } from "./MaterialCardContentHeader";
import { MaterialCardCollabTitle } from "./MaterialCardCollabTitle";
import { MaterialCardProvider } from "./MaterialCardProvider";
import { Listing } from "../../../../interfaces/training-plan/Listing";
import { MaterialCardSubtitle } from "./MaterialCardSubtitle";
import { MaterialCardTitle } from "./MaterialCardTitle";
import { MaterialCardRating } from "./MaterialCardRating";
import React from "react";
import { MaterialCardDurationFrequency } from "./MaterialCardDurationFrequency";
import { MaterialCardBottomWrapper } from "./MaterialCardBottomWrapper";
import { MaterialCardCoachAndCollab } from "./MaterialCardCoachAndCollab";
import { MaterialCardCollabTitleWrapper } from "./MaterialCardCollabTitleWrapper";
import { MaterialCardUserScore } from "./MaterialCardUserScore";

type MaterialCardProps = {
  data: Listing;
};

export const MaterialCard = ({ data }: MaterialCardProps) => {
  return (
    <MaterialCardProvider listing={data}>
      <MaterialCardLink>
        <MaterialCardWrapper>
          <MaterialCardMedia />

          <MaterialCardContentWrapper>
            <MaterialCardContentHeader>
              <MaterialCardSubtitle />
              <MaterialCardTitle />
              <MaterialCardRating />
            </MaterialCardContentHeader>

            <MaterialCardDurationFrequency />

            <MaterialCardBottomWrapper>
              <MaterialCardCoachAndCollab />

              <MaterialCardCollabTitleWrapper>
                <MaterialCardCollabTitle />
                <MaterialCardUserScore />
              </MaterialCardCollabTitleWrapper>
            </MaterialCardBottomWrapper>
          </MaterialCardContentWrapper>
        </MaterialCardWrapper>
      </MaterialCardLink>
    </MaterialCardProvider>
  );
};
