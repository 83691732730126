import React, { createContext, Dispatch, useContext, useReducer } from "react";
import { Listing } from "../../../../interfaces/training-plan/Listing";

type MaterialCardContextProps = {
  state: MaterialCardState;
  dispatch: Dispatch<MaterialCardState>;
};

type MaterialCardState = {
  data?: Listing;
};

const initialState: MaterialCardState = {
  data: null,
};

const materialCardReducer = (
  state: MaterialCardState,
  newState: MaterialCardState,
): MaterialCardState => {
  return {
    data: newState.data ?? state.data,
  };
};

const MaterialCardContext = createContext<MaterialCardContextProps | undefined>(
  undefined,
);

export const MaterialCardProvider = ({
  children,
  listing,
}: {
  children: React.ReactNode;
  listing: Listing;
}) => {
  const [state, dispatch] = useReducer(materialCardReducer, { data: listing });

  return (
    <MaterialCardContext.Provider value={{ state, dispatch }}>
      {children}
    </MaterialCardContext.Provider>
  );
};

export const useMaterialCardContext = () => {
  const context = useContext(MaterialCardContext);

  if (!context) {
    throw new Error(
      "useMaterialCardContext must be used within a MaterialCardProvider",
    );
  }

  return context;
};
