import { buildUrlName } from "../../../../lib/generic";
import Link from "next/link";
import { useMaterialCardContext } from "./MaterialCardProvider";
import React from "react";

type MaterialCardLinkProps = {
  children?: React.ReactNode;
};

export const MaterialCardLink = ({
  children,
  ...props
}: MaterialCardLinkProps) => {
  const { state } = useMaterialCardContext();

  const url =
    state.data.type === "training"
      ? "/training-plan/[id]/[name]"
      : "/resources/[id]/[name]";

  return (
    <Link
      href={{
        pathname: url,
        query: {
          id: state.data?.id,
          name: buildUrlName(state.data?.title),
        },
      }}
      data-cy="explore-training-plan"
      {...props}
    >
      {children}
    </Link>
  );
};
