import { Typography } from "@mui/material";
import React from "react";

type BadgeExplanationDescriptionProps = React.ComponentProps<"div">;

export const BadgeExplanationDescription = ({
  children,
}: BadgeExplanationDescriptionProps) => {
  return (
    <Typography className={"text-gray-650 text-sm"}>{children}</Typography>
  );
};
