import { cn } from "../../../../helpers/cn";
import React from "react";

type ScoreStarRatingWrapperProps = React.ComponentProps<"div">;

export const ScoreStarRatingWrapper = ({
  children,
  className,
  ...props
}: ScoreStarRatingWrapperProps) => {
  return (
    <div
      className={cn("flex items-center justify-start ", className)}
      {...props}
    >
      {children}
    </div>
  );
};
