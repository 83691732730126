import { Typography, TypographyProps } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { cn } from "../../../../helpers/cn";

type AppRatingDescriptionProps = TypographyProps;

export const AppRatingDescription = ({
  children,
  className,
  ...props
}: AppRatingDescriptionProps) => {
  const theme = useSelector((state: RootState) => state.theme);

  return (
    <Typography
      className={cn("text-[14px]", className)}
      sx={{
        color: theme.componentColors.surface.onSurfaceVariant,
      }}
      {...props}
    >
      {children}
    </Typography>
  );
};
