import { BadgeOptions } from "../../enums/BadgeOptions";

type VerificationBadgeColor = {
  isFromSpecialist: boolean;
  isFromTeam: boolean;
  type: BadgeOptions;
};

export const verificationBadgeColor = ({
  isFromSpecialist,
  isFromTeam,
  type,
}: VerificationBadgeColor): string => {
  let color = "";

  if (isFromSpecialist && type == BadgeOptions.pro) {
    color = "#1EC8FF";
  }

  if (isFromSpecialist && type != BadgeOptions.pro) {
    color = "#FF9800";
  }

  if (isFromTeam && type == BadgeOptions.verified) {
    color = "#B6B0A8";
  }

  return color;
};
