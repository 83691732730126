import { Typography, TypographyProps } from "@mui/material";

type SliderTitleBoldProps = TypographyProps;

export const SliderTitleBold = ({
  sx,
  className,
  children,
  ...props
}: SliderTitleBoldProps) => {
  return (
    <Typography
      sx={{
        fontSize: "1.875rem",
        lineHeight: "2.25rem",
        fontWeight: 500,
        ...sx,
      }}
      className={className}
      variant={"h2"}
      {...props}
    >
      {children}
    </Typography>
  );
};
