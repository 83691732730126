import { Rating, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

type IScoreStarRating = {
  value: number;
  description?: string;
  useToFixed?: boolean;
  maxValue?: number;
  starSize?: string;
  spaceBetweenStars?: string;
  showValue?: boolean;
};

export const ScoreStarRating = ({
  value,
  useToFixed = true,
  description,
  maxValue = 5,
  starSize = "16px",
  spaceBetweenStars = "10px",
  showValue = true,
}: IScoreStarRating) => {
  const theme = useSelector((state: RootState) => state.theme);

  return (
    <Stack direction={"row"} className="flex items-center justify-start">
      <Rating
        max={maxValue}
        readOnly
        value={value}
        precision={0.5}
        sx={{
          color: theme.componentColors.primary.primary,
          fontSize: starSize,
          "&.MuiRating-root .MuiRating-decimal": {
            marginRight: spaceBetweenStars,
          },
        }}
      />
      <Typography
        className="text-[14px]"
        sx={{
          color: theme.componentColors.surface.onSurfaceVariant,
        }}
      >
        {useToFixed && showValue && value.toFixed(1)}{" "}
        {description ? description : null}
      </Typography>
    </Stack>
  );
};
