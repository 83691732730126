import { CustomChip } from "../../../global/chips/CustomChip";
import { useMaterialCardContext } from "./MaterialCardProvider";

export const MaterialCardDurationFrequency = () => {
  const { state } = useMaterialCardContext();
  const data = state.data;

  return (
    <div className={"flex space-x-[0.625rem]"}>
      {data.trainingPlan && !data.publicListing && (
        <>
          <CustomChip label={`${data.trainingPlan.frequency}x/week`} />
          <CustomChip
            label={`${data.trainingPlan.duration} week${
              data.trainingPlan.duration > 1 ? "s" : ""
            }`}
          />
        </>
      )}
    </div>
  );
};
