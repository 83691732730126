import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import React from "react";
import { AppIconButton } from "../../ui/buttons";
import { cn } from "../../../../helpers/cn";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

type SliderButtonProps = {
  direction?: SliderButtonPropsDirection;
  middlePosition?: boolean;
} & React.ComponentProps<"button">;

export enum SliderButtonPropsDirection {
  LEFT = "left",
  RIGHT = "right",
}

export const SliderButton = ({
  id,
  middlePosition = true,
  direction,
  className,
}: SliderButtonProps) => {
  const directionConfig = {
    [SliderButtonPropsDirection.LEFT]: {
      positionClass: "left-0",
      Icon: ChevronLeftIcon,
    },
    [SliderButtonPropsDirection.RIGHT]: {
      positionClass: "right-0",
      Icon: ChevronRightIcon,
    },
  };

  const { Icon, positionClass } = directionConfig[direction];

  return (
    <AppIconButton
      id={`custom-${direction}-arrow-${id}`}
      sx={{
        "&:disabled": {
          opacity: 0.1,
        },
      }}
      className={cn(
        middlePosition
          ? "absolute top-[50%] transform -translate-y-1/2 z-10"
          : "",
        positionClass,
        className,
        "hidden lg:flex",
      )}
    >
      <Icon className={"text-4xl"} />
    </AppIconButton>
  );
};
