import React from "react";
import { cn } from "../../../../helpers/cn";

type SliderWrapperProps = React.ComponentProps<"div">;

export const SliderWrapper = ({
  children,
  className,
  ...props
}: SliderWrapperProps) => {
  return (
    <div
      className={cn(
        "flex flex-col space-y-3.5 lg:space-y-7 w-full relative",
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
};
