import { MaterialCardScore } from "./MaterialCardScore";
import React from "react";
import { useMaterialCardContext } from "./MaterialCardProvider";

export const MaterialCardUserScore = () => {
  const { state } = useMaterialCardContext();
  const data = state.data;

  return <MaterialCardScore score={state.data.user.score} />;
};
