import { useMaterialCardContext } from "./MaterialCardProvider";
import React from "react";

type MaterialCardCollabTitleWrapperProps = React.ComponentProps<"div">;

export const MaterialCardCollabTitleWrapper = ({
  children,
  className,
  ...props
}: MaterialCardCollabTitleWrapperProps) => {
  const { state } = useMaterialCardContext();
  const collaborators = state.data.collaborators;

  return (
    <div
      style={{
        paddingLeft: collaborators.length * 40 - 15 * collaborators.length,
      }}
      className={className}
      {...props}
    >
      {children}
    </div>
  );
};
