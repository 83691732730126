import React from "react";

type MaterialCardContentHeaderProps = React.ComponentProps<"div">;

export const MaterialCardContentHeader = ({
  children,
  className,
  ...props
}: MaterialCardContentHeaderProps) => {
  return (
    <div className={className} {...props}>
      {children}
    </div>
  );
};
