import React from "react";
import { Typography } from "@mui/material";

type BadgeVerificationModalTitleProps = React.ComponentProps<"h2">;
export const BadgeVerificationModalTitle = ({
  children,
}: BadgeVerificationModalTitleProps) => {
  return (
    <Typography className={"text-3xl font-semibold"}>{children}</Typography>
  );
};
