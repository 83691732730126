import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { Card, CardProps } from "@mui/material";
import { useRouter } from "next/router";
import { cn } from "../../../../helpers/cn";

type MaterialCardWrapperProps = CardProps;

export const MaterialCardWrapper = ({
  children,
  className,
  ...props
}: MaterialCardWrapperProps) => {
  const componentColors = useSelector(
    (state: RootState) => state.theme.componentColors,
  );
  const router = useRouter();
  const isDarkBackground = router.asPath === "/";

  return (
    <Card
      sx={{
        backgroundColor: isDarkBackground
          ? componentColors.surface.surfaceDim
          : componentColors.surface.surfContainer,
        backgroundImage: "none",
      }}
      className={cn("rounded-b-[16px] rounded-t-[8px]", className)}
      {...props}
    >
      {children}
    </Card>
  );
};
