import { Typography, TypographyProps } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

type SliderTitleVariantOneProps = TypographyProps;

export const SliderTitleVariantOne = ({
  children,
  className,
  ...props
}: SliderTitleVariantOneProps) => {
  const colors = useSelector((state: RootState) => state.theme.componentColors);

  return (
    <Typography
      variant="h2"
      className={className}
      sx={{
        color: colors.surface.onSurfaceDetachable,
        fontSize: "1.5rem",
        lineHeight: "2rem",
        fontWeight: "500",
      }}
      {...props}
    >
      {children}
    </Typography>
  );
};
