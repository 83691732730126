import { Typography, TypographyProps } from "@mui/material";
import React from "react";
import { RootState } from "../../../../redux/store";
import { useSelector } from "react-redux";

type SliderSubtitleProps = TypographyProps;

export const SliderSubtitle = ({
  sx,
  variant = "h3",
  className,
  children,
}: SliderSubtitleProps) => {
  const colors = useSelector((state: RootState) => state.theme.componentColors);

  return (
    <Typography
      sx={{
        fontSize: "0.938rem",
        fontWeight: 400,
        color: colors.surface.onSurfaceVariant,
        ...sx,
      }}
      variant={variant}
      className={className}
    >
      {children}
    </Typography>
  );
};
