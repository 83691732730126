import React from "react";
import { DefaultModal } from "../../modals/material/DefaultModal";
import { useModalContext } from "../../modals/ModalProvider";
import {
  BadgeExplanationDescription,
  BadgeExplanationHeaderContainer,
  BadgeExplanationTitle,
  BadgeVerificationModalCloseBtn,
  BadgeVerificationModalContainer,
  BadgeVerificationModalContentContainer,
  BadgeVerificationModalTitle,
} from "./index";
import { BadgeVerification } from "../BadgeVerification";
import { BadgeOptions } from "../../../../enums/BadgeOptions";
import { BadgeExplanationContainer } from "./BadgeExplanationContainer";

export const BadgeVerificationModal = () => {
  const { closeModal } = useModalContext();

  return (
    <DefaultModal onClose={() => closeModal()}>
      <BadgeVerificationModalContainer>
        <BadgeVerificationModalCloseBtn />
        <BadgeVerificationModalTitle>
          Verification Badges
        </BadgeVerificationModalTitle>
        <BadgeVerificationModalContentContainer>
          <BadgeExplanationContainer>
            <BadgeExplanationHeaderContainer>
              <BadgeExplanationTitle>Pro Coach</BadgeExplanationTitle>
              <BadgeVerification
                type={BadgeOptions.pro}
                isFromSpecialist={true}
              />
            </BadgeExplanationHeaderContainer>

            <BadgeExplanationDescription>
              To be featured as a PRO coach on Pod1um, coaches must be certified
              by a recognized certification body, and have a proven track record
              of working with high-level athletes or teams.
            </BadgeExplanationDescription>
          </BadgeExplanationContainer>

          <BadgeExplanationContainer>
            <BadgeExplanationHeaderContainer>
              <BadgeExplanationTitle>Verified Coach</BadgeExplanationTitle>
              <BadgeVerification
                type={BadgeOptions.verified}
                isFromSpecialist={true}
              />
            </BadgeExplanationHeaderContainer>

            <BadgeExplanationDescription>
              To be featured as a coach on Pod1um, coaches must be certified by
              a recognized certification body, and have at least five years of
              experience.
            </BadgeExplanationDescription>
          </BadgeExplanationContainer>

          <BadgeExplanationContainer>
            <BadgeExplanationHeaderContainer>
              <BadgeExplanationTitle>
                Verified Business/Organisation
              </BadgeExplanationTitle>
              <BadgeVerification type={BadgeOptions.verified} isFromTeam />
            </BadgeExplanationHeaderContainer>

            <BadgeExplanationDescription>
              To be featured as a business or organisation on Pod1um, you must
              be officially registered with a relevant body.
            </BadgeExplanationDescription>
          </BadgeExplanationContainer>
        </BadgeVerificationModalContentContainer>
      </BadgeVerificationModalContainer>
    </DefaultModal>
  );
};
