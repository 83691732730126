import React from "react";

type BadgeVerificationModalContainerProps = React.ComponentProps<"div">;

export const BadgeVerificationModalContainer = ({
  children,
}: BadgeVerificationModalContainerProps) => {
  return (
    <div className={"relative px-4 lg:px-6 pt-4 lg:py-6 max-w-[30rem]"}>
      {children}
    </div>
  );
};
